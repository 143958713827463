import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "./router/home/App";
import Entry from "./router/index/index";
import Mentry from "./m-router/index";
import { ResetPwd } from "./router/index/reset";
import { locales, LocaleProvider } from "antd";
import * as Sentry from "@sentry/browser";
import "./axios";
import { RedeemIndex } from "router/home/redeem/redeem";
import { RedeemPage } from "m-router/redeemphone/redeemCmp";
import { enrollFrontUrl } from "devloader";
import { ResultCCtalkBind } from "router/index/cctalkLoading";
import { NotFound } from "notfound";
import { WxPayCmp } from "./m-router/wxpayEmpty";
import { WxPayResult } from "./m-router/wxresultPage";
import { MiddleForM } from "./m-router/middleForM";
class App extends React.Component {
  state = {
    loading: false,
  };
  //试提交

  componentDidMount() {
    if (enrollFrontUrl === "https://testenroll.houbo.org") {
      // Sentry.init({
      //   dsn: "https://c6dfebe41dc34870b311013454bd96e5@test-sentry.houbo.org/2",
      //   // This sets the sample rate to be 10%. You may want this to be 100% while
      //   // in development and sample at a lower rate in production
      //   replaysSessionSampleRate: 1.0,
      //   // If the entire session is not sampled, use the below sample rate to sample
      //   // sessions when an error occurs.
      //   replaysOnErrorSampleRate: 1.0,
      //   integrations: [
      //     new Sentry.Replay({
      //       // Additional SDK configuration goes in here, for example:
      //       maskAllText: false,
      //       maskAllInputs: false,
      //       blockAllMedia: false,
      //     }),
      //   ],
      // });
    } else {
      // Sentry.init({
      //   dsn: "https://42935f00d36547e2bd2a8aa275e1ff0f@test-sentry.houbo.org/8",
      //   replaysSessionSampleRate: 1.0,
      //   replaysOnErrorSampleRate: 1.0,

      //   integrations: [
      //     new Sentry.Replay({
      //       maskAllText: false,
      //       maskAllInputs: false,
      //       blockAllMedia: false,
      //     }),
      //   ],
      // });
    }
  }
  render() {
    const Wd = document.body.clientWidth;
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/redeem"
            component={Wd < 750 ? RedeemPage : RedeemIndex}
          />
          <Route path="/middleForM" component={MiddleForM} />
          <Route path="/wxpay" component={WxPayCmp} />
          <Route
            path={["/paymentResult/:id", "/paymentResult"]}
            component={WxPayResult}
          />
          <Route path="/" exact component={Entry} />

          <Route path="/reset" component={ResetPwd} />
          <Route path="/todo_cctalk_bind" component={ResultCCtalkBind} />
          <Route path="/home">
            <LocaleProvider locale={locales.zh_CN}>
              <Route component={Wd <= 750 ? Mentry : Home} />
            </LocaleProvider>
          </Route>

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
