import Axios from "axios";
import { useEffect, useState } from "react";
import { message } from "antd";
// import * as Sentry from "@sentry/react";
import { enrollFrontUrl } from "devloader";
export const is_weixn = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};
export const getUserinformation = async () => {
  var res = localStorage.jwt ? await Axios.get("/profile") : { data: {} };
  if (res.data.code !== 0) {
    return {};
  }
  if (enrollFrontUrl === "https://enroll.houbo.org") {
    // Sentry.setUser({
    //   id: res.data.data.id,
    //   username: res.data.data.phoneNumber,
    //   ip_address: "{{auto}}",
    //   email: res.data.data.emailAddress,
    // });
  }
  sessionStorage.userData = JSON.stringify(res.data.data);
  return res.data.data;
};
export const chunkArray = (array, size) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
};
export const isRegister = async (userName) => {
  try {
    let res = await Axios.post("/user_exist", { userName });
    if (res.data.code !== 0) {
      message.destroy();
      message.error(res.data.message);
      return false;
    } else {
      return res.data.data.existing;
    }
  } catch (err) {
    console.log(err);
  }
};

export const parsePrice = (b, c) => ((b * 100 - c * 100) / 100).toFixed(2) * 1;
export const parsePriceSum = (a, b) =>
  ((a * 100 + b * 100) / 100).toFixed(2) * 1;
export const parseReduceSum = (a, b) =>
  ((a * 100 + b * 100) / 100).toFixed(2) * 1;
export const parseReduceMinus = (a, b) =>
  ((a * 100 - b * 100) / 100).toFixed(2) * 1;
export const Tick = (props) => {
  let { reloadFn, deadline, createTime } = props;

  let orign_time = Date.parse(new Date()) / 1000;

  let leftTime = deadline; //转化成秒,传入的是时间戳

  let maxTime = leftTime - orign_time;

  if (createTime) {
    maxTime = deadline - createTime;
  }

  const [data, setData] = useState(maxTime);

  useEffect(() => {
    let timer = null;
    const tick = () => {
      let orign_time = Date.parse(new Date()) / 1000;

      let leftTime = deadline; //转化成秒,传入的是时间戳

      let nData = leftTime - orign_time;
      setData(() => nData--);
      timer = setInterval(() => {
        if (nData < 0) {
          createTime && reloadFn();
          clearInterval(timer);
        } else {
          setData(() => nData--);
        }
      }, 1000);
    };
    tick();
    return () => {
      timer && clearInterval(timer);
    };
  }, [deadline, reloadFn, createTime]);

  return <span>{Component(data, createTime)}</span>;
};

const Component = (maxTime, createTime) => {
  let that = {};

  that.hours =
    parseInt((maxTime / 60 / 60) % 24, 10) < 10
      ? "0" + parseInt((maxTime / 60 / 60) % 24, 10)
      : parseInt((maxTime / 60 / 60) % 24, 10); //计算剩余的小时

  that.minutes =
    parseInt((maxTime / 60) % 60, 10) < 10
      ? "0" + parseInt((maxTime / 60) % 60, 10)
      : parseInt((maxTime / 60) % 60, 10); //计算剩余的分钟

  that.seconds =
    parseInt(maxTime % 60, 10) < 10
      ? "0" + parseInt(maxTime % 60, 10)
      : parseInt(maxTime % 60, 10); //计算剩余的秒数
  return createTime && maxTime < 0
    ? "00:00:00"
    : that.hours + ":" + that.minutes + ":" + that.seconds;
};

export const parseCourseState = (status, payStage) => {
  const statusText = ["等待付款", "完成付款", "报名成功", "已取消"];
  const Text = ["等待付款", "等待付款(阶段1)", "等待付款(阶段2)", "完成付款"];
  if (status === 0) {
    return Text[payStage];
  }
  if (status === 3) {
    return statusText[status];
  }
  return statusText[status];
};

//{status===0?PayState[payStage]:status===3?<span className='gray_color'>{PayStatus[status]}</span> :PayStatus[status]}
